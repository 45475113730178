<template>
  <div>
    <header>
      <Navbar />
    </header>
    <main>
      <section class="bg-gradient">
        <div class="jumbotron position-relative mb-0">
          <div class="qa-title">
            <h5 class="mb-3 font-weight-bold font-small-36">
              問與答
            </h5>
            <p class="font-weight-bold font-small-60 border-gold">
              FAQS
            </p>
          </div>
        </div>
      </section>
      <section>
        <div class="container py-3 py-xl-5 text-left">
          <div class="d-flex flex-wrap justify-content-center no-gutters">
            <div class="col-xl-8 mb-2 mb-xl-5">
              <div
                class="d-flex justify-content-around font-md-24 font-weight-bold"
              >
                <span
                  :class="{
                    'border-bottom-green': questionContent === 'menberQuestion'
                  }"
                  class="py-1"
                  @click="changeQuestionType('menberQuestion')"
                >會員申請</span>
                <span
                  :class="{
                    'border-bottom-green': questionContent === 'transaction'
                  }"
                  class="py-1"
                  @click="changeQuestionType('transaction')"
                >交易問題</span>
                <span
                  :class="{ 'border-bottom-green': questionContent === 'pay' }"
                  class="py-1"
                  @click="changeQuestionType('pay')"
                >繳款問題</span>
                <span
                  :class="{ 'border-bottom-green': questionContent === 'fa' }"
                  class="py-1"
                  @click="changeQuestionType('fa')"
                >fa 券問題</span>
              </div>
            </div>
            <div
              v-if="questionContent === 'menberQuestion'"
              class="col-xl-8"
            >
              <div class="border-top position-relative">
                <p class="pt-3 font-small-24">
                  如何成為會員？
                </p>
                <p class="mb-0">
                  您只要年滿18歲並持有中華民國身分證，下載fasney
                  App就可加入會員。(即申請消費額度)
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  要附什麼資料
                </p>
                <p class="mb-0">
                  依流程提示上傳證件、如實填寫資料即可
                  (如需補件會再以簡訊、App推播通知)
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  審核要多久
                </p>
                <p class="mb-0">
                  一天內即可得知審核結果
                  (審核過程可能需要與您通話核對資料，故請留意手機來電及相關訊息通知)
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  客服時間
                </p>
                <p class="mb-0">
                  服務時間為平日09:00~18:00 (12:00~13:00為午休時間)
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  最近有辦無卡分期、貸款、信用卡還可以申請嗎?
                </p>
                <p class="mb-0">
                  可以申請，雖然密集申辦金融服務不是加分的情況，但我們仍會務實地評估。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  債務協商及信用瑕疵紀錄可否申辦？
                </p>
                <p class="mb-0">
                  可以申請，我們會評估您的工作收入及相關債務狀況。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <ul>
                  <li class="pt-3 font-small-24">
                    哪些可以算財力證明
                  </li>
                  <li class="py-1">
                    財力證明：
                  </li>
                  <li class="py-1 text-indent-1">
                    1. 日常使用及薪轉存摺，需附帳號封面及最近三個月完整內頁。
                  </li>
                  <li class="py-1 text-indent-1">
                    2. 房屋權狀 / 稅單
                  </li>
                  <li class="py-1">
                    工作或收入證明：
                  </li>
                  <li class="py-1 text-indent-1">
                    1. 現職的所得扣繳憑單
                  </li>
                  <li class="py-1 text-indent-1">
                    2. 公司識別證及名片
                  </li>
                  <li class="py-1">
                    其他證明：
                  </li>
                  <li class="py-1 text-indent-1">
                    1. 近一期信用卡帳單完整影本
                  </li>
                </ul>
              </div>
            </div>
            <div
              v-if="questionContent === 'transaction'"
              class="col-xl-8"
            >
              <div class="border-top position-relative">
                <p class="pt-3 font-small-24">
                  如何在店家做使用
                </p>
                <p class="mb-0">
                  在合作店家的結帳流程選擇「fasney後支付」付款，依畫面提示進行操作即可。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  如何設定分期
                </p>
                <p class="mb-0">
                  當每期消費結帳後，可在fasney
                  App的帳單畫面，將當期消費金額轉換為「慢慢還」，效果與分期付款相同
                </p>
              </div>
              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  如果後支付額度還沒審核通過，可以在店家購物結帳嗎？
                </p>
                <p class="mb-0">
                  可以的，送出訂單後，付款狀態會變為「等待付款」，待額度審核通過後訂單狀態則自動變成「付款成功」。(無需重新結帳)
                </p>
              </div>
            </div>
            <div
              v-if="questionContent === 'pay'"
              class="col-xl-8"
            >
              <div class="border-top position-relative">
                <p class="pt-3 font-small-24">
                  繳款方式有哪些呢？
                </p>
                <p class="mb-0">
                  1. 可至四大超商臨櫃繳款 (如：7-11、全家、萊爾富、OK)
                </p>
                <p class="mb-0">
                  2. 使用網路銀行或ATM轉帳
                </p>
              </div>
              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  可否提前繳款
                </p>
                <p class="mb-0">
                  每月結帳後，您可依照帳單金額隨時繳款。
                  若為「慢慢還」、「好好貸」等以日計息的服務，每天都可以還款。
                </p>
              </div>
              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  剛繳款但顯示還沒繳？
                </p>
                <p class="mb-0">
                  因代收作業非即時，我們約兩個工作天後才會收到繳款通知並核銷；您可先保留收據(小白單)
                  ，待兩個工作天後再確認有無入帳。
                </p>
              </div>
              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  超過繳款日會有違約金嗎?
                </p>
                <p class="mb-0">
                  為維護信用權益請盡速繳納，逾期即會產生違約、延滯費用
                </p>
              </div>
            </div>
            <div
              v-if="questionContent === 'fa'"
              class="col-xl-8"
            >
              <div class="border-top position-relative">
                <p class="pt-3 font-small-24">
                  如何使用fa券支付?
                </p>
                <p class="mb-0">
                  於配合通路官網上購買fa券並使用信用卡支付，付款完成隔天fa券會自動歸戶到fasney帳戶，可折抵歸戶後的訂單，結帳時選取fasney後支付，使用後支付額度完成結帳後，系統會用您帳上的fa券直接抵扣消費金額，足夠折抵就不會再出帳單。
                </p>
                <p class="mb-0">
                  欲查詢fa券餘額，請透過fasney App會員中心→本期帳單，於藍綠底區域下方文字會顯示fa券餘額，若文字被截斷可向左滑動查看。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  如何查看訂單是否成功用fa券支付
                </p>
                <p class="mb-0">
                  尚未出帳前，無法查看折抵紀錄，但可點選當期帳單查看fa券餘額，系統有折抵就會扣除。
                </p>
                <p class="mb-0">
                  若消費金額超過fa券餘額，剩下的差額也會占用原核准額度， 結帳日後就會出帳單。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  fa券支付後不足的差額如何付款?
                </p>
                <p class="mb-0">
                  fa券全額折抵後若尚有差額，則會出帳單通知繳款。
                </p>
                <p class="mb-0">
                  帳單會於您的結帳日次日匯入fasney
                  App，出帳將有推撥通知，繳款透過「會員中心」→「本期帳單與繳款」→「繳交款項」，點選繳款金額或也可自行輸入金額，再選擇超商條碼或轉帳帳號進行繳費，未出帳前，想提前繳款也能透過以上操作。
                </p>
                <p class="mb-0">
                  (查詢個人結帳日與繳款日，請在「會員中心」→「申請進度查詢」查看。)
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  已下單但尚未完成會員審核，等待付款的訂單會失效嗎?
                </p>
                <p class="mb-0">
                  訂單有效以商家設定為準，下單時間+2日後將會失效。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  已經有fa券，但仍無法消費
                </p>
                <p class="mb-0">
                  該狀況可能因您尚未申請fasney會員並拿到後支付額度，請先完成申請流程，期間可先消費，待專人審核完成，核發額度後，會自動幫您完成訂單結帳，並用fa券餘額進行支付。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  用fa券支付訂單做退貨，會退款還是fa券呢?
                </p>
                <p class="mb-0">
                  已經用fa券支付完成的訂單，後續若商家通知已完成退貨，我們將會退回fa券至您的fasney帳戶，在使用期限內可繼續於配合通路消費做折抵喔。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  如何查看fa券餘額?
                </p>
                <p class="mb-0">
                  可點選會員中心-本期帳單，藍綠底區域下方文字會顯示fa券餘額，若文字被截斷可向左滑動。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  購買fa券的金額會增加在原始額度嗎?
                </p>
                <p class="mb-0">
                  不會喔，在品牌完成訂單結帳後，fasney將自動幫您用帳戶上的fa券餘額支付消費，但如果訂單已經被fa券支付了，後支付可用額度也會同時恢復喔。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  fa券折抵可以先用加碼金嗎?
                </p>
                <p class="mb-0">
                  需消費到原購買fa券金額，才會有加碼金回饋，因此優先以本金折抵，本金全額折抵完才會使用加碼金。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  fa券需一次用完嗎?
                </p>
                <p class="mb-0">
                  不用喔，fa券可以在任一家配合品牌做消費，可分次折抵。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  沒有要消費了，如何退款fa券?
                </p>
                <p class="mb-0">
                  如果需要申請退款未使用完的fa券，請透過fasney App中的客戶留言功能諮詢。
                </p>
                <p class="mb-0">
                  *退款金額僅有剩餘未使用的fa券本金可退款，加碼金無法退款。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  不同品牌官網註冊買fa券，可否填同一支手機，綁定我的fasney帳號?
                </p>
                <p class="mb-0">
                  無法喔，每位客戶在所有配合品牌中僅能購買fa券一次且數量為一喔，若您同時於一家以上品牌購買fa券，fasney僅會以第一筆成立的交易進行歸戶，其他筆訂單會由品牌做刷退。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  在品牌官網註冊手機跟fasney註冊的不同可以綁定嗎?
                </p>
                <p class="mb-0">
                  無法喔，系統會比對在品牌官網購買fa券與註冊fasney App的客戶手機號碼是否一致，相同才會綁定。
                </p>
              </div>

              <div class="border-top position-relative mt-3">
                <p class="pt-3 font-small-24">
                  要如何詢問fasney客服
                </p>
                <p class="mb-0">
                  如您已有fasney帳號請透過app的客戶留言功能發問，客服可直接查詢您的資料，並盡快處理回覆，若尚未註冊，可發信至官網信箱:service@urfintech.com.tw 詢問。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar'
import Footer from '@/components/common/Footer'
export default {
  name: 'Question',
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      questionContent: this.$route.params.type
    }
  },

  watch: {
    $route() {
      this.questionContent = this.$route.params.type
    }
  },

  methods: {
    changeQuestionType(type) {
      this.questionContent = type
      this.$router
        .push({
          name: 'Question',
          params: { type: this.questionContent }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.jumbotron {
  height: 736px;
}

.border-gold {
  display: inline-block;
  border-bottom: 2px solid #ffcb57;
}

.qa-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 4px;
}

span {
  cursor: pointer;
}

.border-bottom-green {
  border-bottom: 2px solid #75deb0;
}

@media (min-width: 768px) {
  .text-indent-1 {
    text-indent: 1em;
  }
}

@media (min-width: 1200px) {
  main {
    margin-bottom: 150px;
  }
}
</style>
